<!-- src/App.vue -->
<template>
  <v-app>
    <!--
    <div v-if="deviceType === 'mobile'" class="nav-bar">
      <v-btn rounded="0" variant="text" color="#ffffff" icon="mdi-menu"></v-btn>
    </div>
    -->
    <v-snackbar
      :timeout="3000"
      :color="alertMsg.color"
      v-model="alertMsg.state">
      <div class="text-subtitle-1 pb-2">{{ alertMsg.title }}</div>
      <p>{{ alertMsg.content }}</p>
    </v-snackbar>
    <router-view class="router-view" @alert="alert"/>
  </v-app>
</template>
<script>
import { ref } from 'vue';

export default {
  setup() {
    /**
     * get the device type
     */
    const deviceType = ref('');
    if (window.innerWidth <= 600) {
      deviceType.value = 'mobile';
    } else {
      deviceType.value = 'desktop';
    }
    return {
      deviceType
    }
  },
  data(){
    return {
      alertMsg: {
        state: false,
        color: null,
        title:null,
        content:null,
      }
    }
  },
  methods: {
    alert(msg) {
      this.alertMsg = msg;
    }
  },
  mounted() {
    console.log(this.deviceType)
  }
};
</script>
<style scoped>
/** desktop */
@media screen and (min-width: 600px) {
  .nav-bar {
    width: 100%;
    height: 50px;
    display: flex;
    flex-direction: row;
    background-color: var(--theme-color);
  }

  .router-view {
    width: 100vw;
    max-width: 100vw;
  }
}

/** mobile */
@media screen and (max-width: 600px) {
  .nav-bar {
    width: 100%;
    height: 50px;
    display: flex;
    flex-direction: row;
    background-color: var(--theme-color);
  }
  .transparent-btn{
    background-color: transparent;
  }
  .router-view {
    width: 100vw;
    max-width: 100vw;
  }
}
</style>
